<template>
  <div ref="body">
    <custom-header active="design" :subActive="current"></custom-header>

    <div class="content-container">
      
      <div class="left-bar ">
        <!-- <div class="serch-bar flex">
          <div class="serch-box flex align-center">
            <van-icon name="search" />
            <input type="search" v-model="keyword" placeholder="请输入搜索关键词" @keyup.enter="onSearch">
          </div>
          <button class="search-btn" @click="onSearch">搜 索</button>
        </div> -->
        <div class="title text-bold">论坛</div>
        <div class="main-menu">
          <div class="row">
            <template v-for="(item, idx) in categorys">
              <div :key="idx" :class="['theme', categoryId == item.categoryId ? 'active' : '']" @click="categoryChange(item, idx)"><h1>{{item.categoryName}}</h1></div>
            </template>
          </div>
        </div>
        <div class="theme reg-btn text-center" @click="showForm">论坛报名</div>
      </div>
      
      
      <div class="bbs-content">
        <div class="nav">
          <div class="banner-box relative">
            <!-- 当前论坛主题介绍（轮播） -->
            <van-swipe ref="banner" :autoplay="5000" class="" style="width: 100%;">
              <van-swipe-item v-for="(item, idx) in forums" :key="idx">
                <div class="bbs-banner fill relative" @click="showDetail(item)">
                  <img :src="item.forumCover" :alt="item.forumName">
                  <div class="forum-name absolute text-white">{{item.forumName}}</div>
                </div>
              </van-swipe-item>
              <template v-if="forums.length > 1" #indicator>
                <div class="custom-banner-indicator">
                  <van-icon name="down" size="40" class="btn-box left" @click="turnLeft('banner')" />
                  <van-icon name="down" size="40" class="btn-box right" @click="turnRight('banner')" />
                </div>
              </template>
            </van-swipe>
          </div>
          <div class="bbs-left flex">
            <div class="guest relative" @click="toGuests">
              <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/pic1.png" alt="论坛嘉宾" class="img">
              <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/card1.png" alt="论坛日程" class="tname">
            </div>
            <div class="date relative" @click="toForum">
              <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/pic2.png" alt="论坛日程" class="img">
              <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/card2.png" alt="论坛日程" class="tname">
            </div>
          </div>
        </div>
        
        <h1 class="text-bold">论坛新闻</h1>
        
        <div class="news-list">
          <div class="flex flex-wrap">
            <div class="news-item" v-for="(item, i) in news" :key="i" @click="toNewsDetail(item)">
              <img :src="item.newsCover" alt="" class="cover">
              <div>
                <div class="name text-cut text-bold">{{ item.newsTitle }}</div>
                <div class="name2 text-cut2 text-bold">{{ item.newsTitle }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-center">
          <el-pagination background layout="prev, pager, next" :hide-on-single-page="pages <= 1" :page-size="pageSize" :total="total" @current-change="handleCurrentChange"></el-pagination>
        </div>
        
      </div>
      
    </div>
    
    <custom-footer :friendLink="friendLink"></custom-footer>
    
    <!-- 回到顶部 -->
    <scroll-top @scrollTop="scrollTop"></scroll-top>
    
    <!-- 论坛介绍弹框 -->
    <van-overlay :show="show" z-index="10"  @click="closeDetail">
      <div class="" @click.stop>
        <div class="bbs-intro-box bg-white scrollBar">
          <div class=""><h1>{{exh.forumName}}</h1></div>
          <div class="">
            <img :src="exh.forumCover" class="exh-banner" />
          </div>
          <div class="desc">{{exh.introduce}}</div>
        </div>
      </div>
    </van-overlay>
    
    <!-- 论坛报名弹框 -->
    <el-dialog title="论坛报名" :visible.sync="isShowForm" :close-on-click-modal="false" :close-on-press-escape="false" class="custom-dialog">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
        <el-form-item label="" prop="name">
          <span slot="label" class="custom-label">姓名</span>
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="" prop="idCard">
          <span slot="label" class="custom-label">身份证号</span>
          <el-input v-model="form.idCard" maxlength="18"></el-input>
        </el-form-item>
        <el-form-item label="" prop="phone">
          <span slot="label" class="custom-label">手机</span>
          <el-input type="tel" v-model="form.phone" maxlength="11"></el-input>
        </el-form-item>
        <el-form-item label="" prop="company">
          <span slot="label" class="custom-label">公司</span>
          <el-input v-model="form.company"></el-input>
        </el-form-item>
        <el-form-item label="" prop="post">
          <span slot="label" class="custom-label">职务</span>
          <el-input v-model="form.post"></el-input>
        </el-form-item>
        <el-form-item label="" prop="weChat">
          <span slot="label" class="custom-label">微信号</span>
          <el-input v-model="form.weChat"></el-input>
        </el-form-item>
        <el-form-item label="" prop="forumId">
          <span slot="label" class="custom-label custom-label1">论坛</span>
          <el-select v-model="form.forumId" placeholder="请选择论坛" @change="changeForum">
            <el-option v-for="(item, idx) in forums" :key="idx" :label="item.forumName" :value="item.forumOfficialId"></el-option>
            <el-option label="" value=""></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="cancel-btn" @click="isShowForm = false">取 消</el-button>
        <el-button class="submit-btn" type="primary" @click="forumEnroll">确 定</el-button>
      </div>
    </el-dialog>
    
  </div>
</template>

<script>
import { Swipe, SwipeItem, Icon, Empty, Dialog, Toast, Overlay } from 'vant';
import customHeader from "./components/head.vue";
import customFooter from "./components/foot.vue";
import scrollTop from "./components/scrollTop.vue";
import commonMixin from './commonMixin.js';
import { getForumList, getNewsList, getCommonCategory, addForumEnroll } from '@/api/index.js'

export default {
  // name: '高峰论坛',
  components: {
    'custom-header': customHeader,
    'custom-footer': customFooter, 'scroll-top': scrollTop,
    [Icon.name]: Icon,
    [Toast.name]: Toast,
    [Overlay.name]: Overlay,
    [Empty.name]: Empty,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  mixins: [commonMixin],
  metaInfo: {
    meta: [
      { name: 'keyWords', content: '深圳家具展，家具展，家居设计，家具展商，家居论坛，设计周，家居设计周' },
      { name: 'description', content: '深圳家居设计周暨40届深圳国际家具展，将于2025年3月16-19日在深圳国际会展中心盛大启幕，是目前国内唯一横向打通住宅、建筑、室内、家具、软装、灯光及工作环境全产业链的产业创新与教育分享展。' },
    ],
  },
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!/^1\d{10}$/.test(value)) {
        callback(new Error('手机号格式错误'));
      } else {
        callback();
      }
    };
    var checkIdCard = (rule, value, callback) => {
      if (!/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value)) {
        callback(new Error('身份证号格式错误'));
      } else {
        callback();
      }
    };
          
    return {
      current: 'bbs',
      pageNo: 1,
      pageSize: 12,
      total: 0,
      pages: 1,
      news: [],
      keyword: '',
      categoryId: '',
      categorys: [],
      forums: [],
      isShowForm: false,
      form: {
        name: '',
        forumId: '',
        forumName: '',
        idCard: '',
        phone: '',
        company: '',
        post: '',
        weChat: '',
      },
      rules: {
        name: { required: true, message: '请输入姓名', trigger: 'blur' },
        idCard: [
          { required: true, message: '请输入身份证号', trigger: 'blur' },
          { validator: checkIdCard, trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: checkPhone, trigger: 'blur' },
        ],
        company: { required: true, message: '请输入公司名称', trigger: 'blur' },
        post: { required: true, message: '请输入职务', trigger: 'blur' },
        weChat: { required: true, message: '请输入微信号', trigger: 'blur' },
        forumId: { required: true, message: '请选择论坛', trigger: 'change' },
      },
      show: false,
      exh: {},
    }
  },
  created() {
    this.getCategorys();
  },
  methods: {
    // onSearch() {
    //   this.pageNo = 1;
    //   this.getNews();
    // },
    // 翻页
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getNews();
    },
    categoryChange(row) {
      if (row.categoryId == this.categoryId) return;
        
      this.categoryId = row ? row.categoryId : '';
      this.pageNo = 1;
      this.getNews();
      this.getForums();
    },
    // 获取论坛分类
    getCategorys() {
      getCommonCategory().then(res => {
        let categorys = res.resultData || [];
        this.categorys = categorys;
        if (this.categorys.length) {
          this.categoryId = this.categorys[0].categoryId;
          this.getNews();
          this.getForums();
        }
      })
    },
    // 获取论坛
    getForums() {
      let params = {
        pageNo: 1,
        pageSize: 10,
        categoryId: this.categoryId,
      };
      getForumList(params).then(res => {
        this.forums = res.resultData.records || [];
      })
    },
    getNews() {
      let params = {
        keyword: this.keyword,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        categoryId: this.categoryId,
        type: 3, // 0-逛展小程序展会资讯, 1-官网展会资讯, 2-官网品牌资讯, 3-官网论坛资讯
        random: false,
      };
      getNewsList(params).then(res => {
        let {records = [], total = 0, pages = 1 } = res.resultData;
        this.total = total;
        this.pages = pages;
        this.news = records;
      })
    },
    showDetail(row) {
      this.exh = row;
      this.show = true;
    },
    closeDetail() {
      this.exh = {}
      this.show = false;
    },
    toNewsDetail(row) {
      this.$router.push({
        path: '/newsDetail',
        query: {
          id: row.newsId
        }
      })
    },
    toForum() {
      this.$router.push({
        path: '/forum',
        query: {
          categoryId: this.categoryId
        }
      })
    },
    toGuests() {
      this.$router.push({
        path: '/guest',
        query: {
          categoryId: this.categoryId
        }
      })
    },
    changeForum(val) {
      if (val) {
        this.form.forumName = this.forums.find(e => e.forumOfficialId == val)?.forumName || '';
      }
    },
    showForm() {
      this.isShowForm = true;
      this.form = {
        name: '',
        forumId: '',
        forumName: '',
        idCard: '',
        phone: '',
        company: '',
        post: '',
        weChat: '',
      };
    },
    forumEnroll() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          addForumEnroll(this.form).then(res => {
            if (res.resultStatus) {
              Toast({
                duration: 2000,
                message: '报名成功'
              });
              this.isShowForm = false;
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    turnLeft(key) {
      this.$refs[key].prev();
    },
    turnRight(key) {
      this.$refs[key].next();
    },
  },
}
</script>

<style lang="less">

// 移动端
@media screen and (max-width: 1023px) {
  .el-select-dropdown {
    border-radius: 2px;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,.1);
    margin: 4px 0;
    margin-top: 8px!important;
    margin-bottom: 4px!important;
    border: 1px solid #E4E7ED;
    width: 280px;
    left: 45px!important;
    
    .el-select-dropdown__list {
      width: 100%;
      padding: 5px 0;
    }
    
    .el-select-dropdown__item {
      width: 280px;
      font-size: 14px;
      padding: 0 8px;
      height: 28px;
      line-height: 2em;
      box-sizing: border-box;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    
  }
  
  .van-toast {
    min-width: 100px;
    font-size: 15px;
    line-height: 1em;
    padding: 1em;
  }
}

// pc端
@media screen and (min-width: 1024px) {
  .el-select-dropdown {
    border-radius: 2px;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,.1);
    margin: 4px 0;
    margin-top: 8px!important;
    margin-bottom: 4px!important;
    border: 0.5px solid #E4E7ED;
    
    .el-select-dropdown__list {
      padding: 2px 0;
    }
    
    .el-select-dropdown__item {
      font-size: 4.8px;
      padding: 0 4px;
      height: 10px;
      line-height: 10px;
    }
    
  }
  
  .van-toast {
    min-width: 60px;
    font-size: 5px;
    line-height: 1em;
    padding: 1em;
  }
}

</style>

<style scoped lang="less">
// 移动端
@media screen and (max-width: 1023px) {
  .custom-dialog {
    /deep/ .el-dialog {
      width: 300px;
      
      .el-dialog__header {
        padding: 20px 50px;
        text-align: center;
        
        .el-dialog__title {
          font-size: 20px;
          font-family: 'SourceHanSansCN-Bold';
        }
        
        .el-dialog__headerbtn {
          top: 8px;
          right: 10px;
          font-size: 24px;
        }
      }
      
      .el-dialog__body {
        padding: 10px 20px;
        
        .el-form-item {
          margin-bottom: 18px;
          display: flex;
          align-items: center;
        }
        
        .el-form-item__label {
          width: 90px!important;
          // height: 8px;
          font-size: 13px;
          line-height: 14px;
          color: #060211;
          padding: 5px 10px 0 0;
          
          .custom-label {
            font-size: 13px;
          }
          .custom-label1 {
            margin-bottom: 10px;
          }
          
        }
        
        .el-form-item__content {
          margin-left: 0!important;
          width: 195px;
          line-height: 20px;
          
          .el-form-item__error {
            font-size: 12px;
            line-height: 1em;
            padding-top: 5px;
          }
          
          .el-select {
            display: block;
            // margin-top: 0;
          }
          .el-input {
            font-size: 12px;
            
            .el-input__inner {
              border-radius: 4px;
              border: 1px solid #DCDFE6;
              height: 28px;
              line-height: 28px;
              margin-top: 5px;
              padding: 0 10px;
              color: #060211;
            }
            
            .el-input__suffix {
              right: 8px;
              height: 28px;
              line-height: 28px;
              top: 6px;
              
              .el-input__icon {
                width: 14px;
                line-height: 28px;
              }
            }
          }
          
          .el-select .el-input .el-select__caret {
            font-size: 14px;

            // &.is-reverse {
            //   transform: rotateZ()!important;
            //   line-height: 28px;
            // }
          }
        }
        
        .el-form-item.is-error .el-input__inner,
        .el-form-item.is-error .el-input__inner:focus, 
        .el-form-item.is-error .el-textarea__inner, 
        .el-form-item.is-error .el-textarea__inner:focus, 
        .el-message-box__input input.invalid, 
        .el-message-box__input input.invalid:focus {
            border-color: #F56C6C;
        }
      
      }
    
      .el-dialog__footer {
        padding: 2px 10px 30px;
        text-align: center;
        
        .submit-btn,
        .cancel-btn {
          border: 1px solid #DCDFE6;
          padding: 10px 20px;
          font-size: 14px;
          border-radius: 4px;
        }
        .submit-btn {
          border-color: #409EFF;
        }
      }
    }
  }
  
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  .content-container {
    width: 375px;
    margin: 0 auto 20px;
    
    .left-bar {
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
      
      .title {
        display: none;
      }
      
      .main-menu {
        width: 100%;
        overflow-x: auto;
        
        &::-webkit-scrollbar {
          height: 0;
        }
        
        .row {
          width: auto;
          white-space: nowrap; 
          
          .theme {
            display: inline-block;
            width: 160px;
            cursor: pointer;
            text-align: center;
            border-radius: 4px;
            border: 1px solid #fff;
            line-height: 30px;
            
            &.active {
              border-color: #060211;
            }
            h1 {
              font-weight: normal;
              font-size: 13px;
              font-family: 'SourceHanSansCN-Medium';
            }
            
          }
        }
        
      }
      
      .reg-btn {
        background-color: #00ABA5;
        color: #fff;
        width: 350px;
        line-height: 2.5em;
        margin: 10px auto;
        border-radius: 4px;
        font-size: 14px;
        letter-spacing: 0.5px;
      }
      
    }
    
    .bbs-content {
      width: 100%;
      
      .nav {
        width: 100%;
        // height: 67px;
        font-size: 6px;
        text-align: center;
        margin-bottom: 30px;
        
        .bbs-left {
          padding: 0 5px;
          
          .guest,
          .date {
            width: 175px;
            margin: 5px;
            
            .img {
              width: 100%;
              height: auto;
              border-radius: 4px;
            }
            
            .tname {
              position: absolute;
              left: 0;
              top: 0;
              z-index: 1;
              width: 100%;
              height: auto;
              border-radius: 4px;
            }
            
          }
        }
        
        .banner-box {
          width: 100%;
          margin-bottom: 10px;
          
          .bbs-banner {
            img {
              display: block;
              width: 100%;
              height: auto;
            }
            
            .forum-name {
              left: 0;
              bottom: 0;
              z-index: 1;
              background-color: rgba(0, 0, 0, .4);
              width: 100%;
              line-height: 2em;
              font-size: 14px;
              border-radius: 0 0 1px 1px;
            }
          }
          
          .custom-banner-indicator {
            display: none;
          }
        }
      }
      
      h1 {
        font-size: 16px;
        text-align: center;
        margin-bottom: 20px;
      }
      
      .news-list {
        
        width: 375px;
        padding: 0 5px;
        
        .news-item {
          width: 172px;
          margin: 0 5px 10px;
          padding-bottom: 2px;
          border-radius: 1px;
          
          .cover {
            display: block;
            width: 100%;
            height: auto;
            border-radius: 4px;
          }
          
          .name2 {
            display: none;
          }
          .name {
            width: 100%;
            padding: 5px;
            box-sizing: border-box;
            margin-bottom: 1px;
            font-size: 14px;
          }
          
          .intro {
            font-size: 3.2px;
            padding: 0 2px;
            margin-bottom: 2px;
          }
          
        }
        
      }
      
      /deep/ .el-pagination.is-background {
        
        .btn-next .el-icon, 
        .btn-prev .el-icon {
          font-size: 3.6px;
          font-weight: 700;
          height: 8px;
          line-height: 8px;
        }
        
        .btn-next,
        .btn-prev, 
        .el-pager li {
          margin: 0 1px;
          color: #606266;
          min-width: 8px;
          border-radius: 1px;
          padding: 0 1px;
          font-size: 3.6px;
          height: 8px;
          line-height: 8px;
        }
        
        .el-pager li:not(.disabled).active {
          background-color: #409EFF;
          color: #FFF;
        }
        
        .el-pager .more::before {
          line-height: 8px;
        }
      }
    
    }
    
    
  }
  
  .bbs-intro-box {
    position: absolute;
    z-index: 11;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 360px;
    padding: 20px 10px;
    border-radius: 4px;
    overflow: auto;
    
    h1 {
      font-size: 15px;
    }
    
    .exh-banner {
      display: block;
      width: 100%;
      height: auto;
      margin: 10px auto;
      border-radius: 1px;
    }
    
    .desc {
      font-size: 14px!important;
      line-height: 1.5em;
    }
  }
  
  
}

// pc端
@media screen and (min-width: 1024px) {
  .custom-dialog {
    /deep/ .el-dialog {
      width: 150px;
      // margin-top: 6vh!important;
      
      .el-dialog__header {
        padding: 5px 10px;
        text-align: center;
        
        .el-dialog__title {
          font-size: 6px;
          line-height: 10px;
        }
        
        .el-dialog__headerbtn {
          top: 5px;
          right: 8px;
          font-size: 8px;
        }
      }
      
      .el-dialog__body {
        padding: 4px 10px;
        
        .el-form-item {
          margin-bottom: 4px;
          display: flex;
          align-items: center;
        }
        
        .el-form-item__label {
          width: 33px!important;
          // height: 8px;
          font-size: 4px;
          line-height: 4px;
          color: #060211;
          padding: 4px 6px 0 0;
          
          .custom-label {
            font-size: 4.8px;
          }
          .custom-label1 {
            margin-bottom: 1px;
          }
          
        }
        
        .el-form-item__content {
          margin-left: 0!important;
          width: 95px;
          line-height: 10px;
          
          .el-form-item__error {
            font-size: 4px;
            line-height: 1;
            padding-top: 2px;
          }
          
          .el-select {
            display: block;
            margin-top: 4px;
          }
          .el-input {
            font-size: 4px;
            
            .el-input__inner {
              border-radius: 1.5px;
              border: 0.5px solid #DCDFE6;
              height: 10px;
              line-height: 10px;
              padding: 0 4px;
              color: #060211;
            }
            
            .el-input__suffix {
              right: 2px;
              height: 10px;
              top: 0;
              
              .el-input__icon {
                width: 8px;
                line-height: 10px;
              }
            }
          }
          
          .el-select .el-input .el-select__caret {
            font-size: 5px;
          }
        }
        
        .el-form-item.is-error .el-input__inner,
        .el-form-item.is-error .el-input__inner:focus, 
        .el-form-item.is-error .el-textarea__inner, 
        .el-form-item.is-error .el-textarea__inner:focus, 
        .el-message-box__input input.invalid, 
        .el-message-box__input input.invalid:focus {
            border-color: #F56C6C;
        }
      
      }
    
      .el-dialog__footer {
        padding: 2px 10px 10px;
        text-align: center;
        
        .submit-btn,
        .cancel-btn {
          border: 0.5px solid #DCDFE6;
          padding: 3px 8px;
          font-size: 5px;
          border-radius: 1px;
        }
        .submit-btn {
          border-color: #409EFF;
        }
      }
    }
  }
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  
  .content-container {
    display: flex;
    width: 290px;
    margin: 20px auto ;
    
    .left-bar {
      width: 80px;
      padding: 0 10px 10px;
      box-sizing: border-box;
      
      .serch-bar {
        margin-bottom: 10px;
        
        .search-btn {
          background-color: #060211;
          color: #fff;
          border: none;
          width: 16px;
          height: 8px;
          border-radius: 1px;
          padding: 0;
          font-size: 3px;
        }
        
        .serch-box {
          width: 54px;
          height: 8px;
          padding: 2px 3px;
          margin-right: 2px;
          box-sizing: border-box;
          background-color: #F7F8FA;
          border-radius: 1px;
          overflow: hidden;
          
          input {
            width: 100%;
            border: none;
            background-color: transparent;
            font-size: 3px;
            padding-left: 2px;
          }
        }
    
        .van-icon-search {
          font-size: 4.8px;
          font-weight: bold;
        }
      }
      
      .title {
        font-size: 6px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 10px;
      }
      
      .theme {
        width: 60px;
        margin: 2px 0;
        cursor: pointer;
        text-align: center;
        border-radius: 1px;
        border: 0.5px solid #fff;
        line-height: 12px;
        
        &.active,
        &:hover {
          border-color: #060211;
        }
        h1 {
          font-weight: normal;
          font-size: 4px;
          font-family: 'SourceHanSansCN-Medium';
        }
        
      }
      
      .reg-btn {
        background-color: #00ABA5;
        color: #fff;
        width: 40px;
        line-height: 2.5em;
        margin: 10px auto;
        border-radius: 1.5px;
        font-size: 4px;
        letter-spacing: 0.5px;
      }
    }
    
    .bbs-content {
      width: 210px;
      
      .nav {
        height: 67px;
        font-size: 6px;
        text-align: center;
        margin-bottom: 10px;
        
        .bbs-left {
          float: left;
          width: 108px;
          
          .guest,
          .date {
            width: 49px;
            margin-right: 5px;
            border-radius: 1px;
            
            .img {
              width: 100%;
              height: auto;
              border-radius: 1px;
            }
            
            .tname {
              position: absolute;
              left: 0;
              top: 0;
              z-index: 1;
              width: 100%;
              height: auto;
              border-radius: 1px;
            }
            
          }
        }
        .banner-box {
          float: right;
          width: 100px;
          border-radius: 1px;
          
          .van-swipe {
            width: 100%!important;
          }
          
          .bbs-banner {
            img {
              display: block;
              width: 100%;
              height: auto;
              border-radius: 1px;
            }
            
            .forum-name {
              left: 0;
              bottom: 0;
              z-index: 1;
              background-color: rgba(0, 0, 0, .4);
              width: 100%;
              line-height: 10px;
              font-size: 3px;
              border-radius: 0 0 1px 1px;
            }
          }
          
          .custom-banner-indicator {
            position: absolute;
            // top: 28px;
            // left: 0;
            // width: 100px;
            // height: 65px;
            
            .btn-box {
              position: absolute;
              width: 10px;
              height: 10px;
              // margin: 0 5Px;
              // padding: 5Px;
              background: rgba(255, 255, 255, 0.3);
              border-radius: 5Px;
              font-size: 6px!important;
              z-index: 20;
              
              &.left {
                left: 0;
                top: -36px;
                padding-top: 3px;
                transform: rotate(90deg);
              }
              &.right {
                right: -100px;
                top: -36px;
                padding-top: 3px;
                transform: rotate(-90deg);
              }
            }
          }
        }
      }
      
      h1 {
        font-size: 5px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 8px;
      }
      
      .news-list {
        
        .news-item {
          width: 51px;
          margin: 0 5px 6px 0;
          padding-bottom: 2px;
          border-radius: 1px;
          
          &:nth-child(4n) {
            margin-right: 0;
          }
          
          &:hover {
            box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
            cursor: pointer;
            
            .cover {
              border-radius: 1px 1px 0 0;
            }
          }
          
          .cover {
            display: block;
            width: 100%;
            height: auto;
            border-radius: 1px;
            background-size: auto 100%;
            background-position: center;
          }
          
          .name {
            display: none;
          }
          .name2 {
            width: 100%;
            padding: 2px 1px;
            box-sizing: border-box;
            margin-bottom: 1px;
            font-size: 3px;
          }
          
          .intro {
            font-size: 3.2px;
            padding: 0 2px;
            margin-bottom: 2px;
          }
          
        }
        
      }
      
      /deep/ .el-pagination.is-background {
        
        .btn-next .el-icon, 
        .btn-prev .el-icon {
          font-size: 3.6px;
          font-weight: 700;
          height: 8px;
          line-height: 8px;
        }
        
        .btn-next,
        .btn-prev, 
        .el-pager li {
          margin: 0 1px;
          color: #606266;
          min-width: 8px;
          border-radius: 1px;
          padding: 0 1px;
          font-size: 3.6px;
          height: 8px;
          line-height: 8px;
        }
        
        .el-pager li:not(.disabled).active {
          background-color: #409EFF;
          color: #FFF;
        }
        
        .el-pager .more::before {
          line-height: 8px;
        }
      }
    
    }
    
  }
  
  .bbs-intro-box {
    position: absolute;
    z-index: 11;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 149px;
    // max-width: 800Px;
    // min-width: 500Px;
    max-height: 80vh;
    padding: 5px;
    border-radius: 2px;
    overflow: auto;
    
    h1 {
      font-size: 5px;
    }
    
    .position {
      color: #aaa;
      font-size: 3.6px;
      margin-top: 1px;
    }
    
    .exh-banner {
      display: block;
      width: 100%;
      height: auto;
      margin: 2px auto;
      border-radius: 1px;
    }
    
    .desc {
      font-size: 3.2px!important;
      line-height: 4.8px;
    }
  }
  
  .scrollBar {
    scrollbar-face-color: #fff;
    /*主色调*/
    scrollbar-highlight-color: #e6eaed;
    scrollbar-shadow-color: #e6eaed;
    /*描边颜色*/
    scrollbar-3dlight-color: #e6eaed;
    /*左侧边高光颜色*/
    scrollbar-arrow-color: transparent;
    /*小三角颜色*/
    scrollbar-track-color: #e6eaed;
    scrollbar-darkshadow-color: #DDD;
  }
  .scrollBar::-webkit-scrollbar {
    /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
    width: 2px;
    height: 0;
    background-color: #A4A4A4;
    border-radius: 50%;
  }
  .scrollBar::-webkit-scrollbar-track {
    /*定义滚动条轨道*/
    background-color: #F1F1F1;
    border-radius: 1px;
  }
  .scrollBar::-webkit-scrollbar-thumb {
    /*定义滑块*/
    border-radius: 2px;
    -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0.3);
  }
  .scrollBar::-webkit-scrollbar-button:vertical  {
    background-color: #ddd;
  }
  

}

</style>
